import React, { useEffect, useState } from 'react';
import { useQueryParam } from 'gatsby-query-params';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getOffer } from '../api/dataApi';

import * as webSocketActions from '../redux/actions/webSocketActions';

import {
  getOfferList,
  getOfferOptionConfig,
  getOfferSettings
} from '../components/Checkout/PlanBuilder';
import StepPlanBuilder from '../components/Checkout/StepPlanBuilder';

function PlanPreview({ webSocket, actions }) {
  const offerID = useQueryParam('offerID', null);
  const [offerList, setOfferList] = useState(null);
  const [offerOptionDictionary, setOfferOptionDictionary] = useState(null);
  const [offerOptionSettings, setOfferOptionSettings] = useState(null);

  useEffect(() => {
    if (offerID && webSocket && webSocket.connected) {
      getOffer(offerID).then((offer) => {
        actions.pageLoading(false);
        setOfferList(getOfferList([{ ...offer, expanded: true }]));
      });
    }
  }, [offerID, webSocket]);

  useEffect(() => {
    if (offerList) {
      const offerOptions = getOfferOptionConfig(offerList);

      setOfferOptionSettings(offerOptions.settings);
      setOfferOptionDictionary(offerOptions.options);
    }
  }, [offerList]);

  return (
    <div className="page-container checkout-container plan-builder-container undocked with-address internet product-type-1">
      <div className="checkout">
        <StepPlanBuilder
          offerOptionSettings={offerOptionSettings}
          setOfferOptionSettings={setOfferOptionSettings}
          offerOptionDictionary={offerOptionDictionary}
          setOfferOptionDictionary={setOfferOptionDictionary}
          offerList={offerList}
          getOfferSettings={getOfferSettings}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanPreview);
